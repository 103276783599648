import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import Footer from '../components/_App/Footer'
import ReactMarkdown from 'react-markdown'
import { dayFormat, monthFormat, yearFormat } from '../helper/dateFormat'
import { getScaledImage } from '../helper/utilities'

const JobDetailsTemplate = ({ data }) => {
  const { title, location, job_type, department, jobDetails, shortDesc, slug } = data.job

  return (
    <Layout>
      <Seo title={title} description={shortDesc} />

      <TopHeader />

      <Navbar />

      <div className="case-details-area pb-70 pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* <div className="row">
                <PageHeading pageTitle={title} />
              </div> */}
              <div className="row">
                <div className="content about-content">
                  <div className="section-title">
                    <span className="sp-color1">{shortDesc}</span>
                    <h2 className="">{title}</h2>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <ul className="about-list text-start ">
                        <li>
                          <i className="bx bx-map-pin" />
                          {location.city}
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <ul className="about-list text-start">
                        <li>
                          <i className="bx bx-alarm" />
                          {job_type.Type}
                        </li>
                        <li />
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <ul className="about-list text-start">
                        <li>
                          <i className="bx bx-bookmark" />
                          {department.name}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="case-article">
                    <div className="case-article-content">
                      <ReactMarkdown>{jobDetails}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pt-70">
              <div className="side-bar-area">
                <div className="side-bar-widget">
                  <h3 className="title">Interested?</h3>
                  <div className="side-bar-categories">
                    <span>
                      Share your resume on{' '}
                      <a href={`mailto:career@innoage.in?subject=Job Id:${slug.toUpperCase()}`}>
                        career@innoage.in
                      </a>{' '}
                      with Job Id: <strong> {slug.toUpperCase()}</strong>. For any queries, feel
                      free to contact us at <a href={`tel:+919205551598`}>+919205551598</a>.
                    </span>
                  </div>
                </div>
                <hr />
                <div className="side-bar-widget">
                  <h3 className="title">Our Blog's</h3>
                  <div className="widget-popular-post">
                    {data.blogs.nodes.map(blog => {
                      return (
                        <article className="item" key={blog.slug}>
                          <Link to={`/blogs/${blog.slug}`} className="thumb">
                            <span className="full-image cover">
                              <img
                                src={getScaledImage(blog.blogImg.url, 90)}
                                alt={blog.title}
                                height="100%"
                              />
                            </span>
                          </Link>
                          <div className="info">
                            <h4 className="title-text">
                              <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                            </h4>
                            <p>
                              {dayFormat(blog.publishedOn)} {monthFormat(blog.publishedOn)},{' '}
                              {yearFormat(blog.publishedOn)}
                            </p>
                          </div>
                        </article>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleJobDetails($slug: String) {
    job: strapiCareers(slug: { eq: $slug }) {
      id
      title
      slug
      location {
        city
      }
      job_type {
        Type
      }
      department {
        name
      }
      jobDetails
      jobId
      shortDesc
    }
    blogs: allStrapiBlog(limit: 5, sort: { fields: publishedOn, order: DESC }) {
      nodes {
        publishedOn
        slug
        title
        updated_at
        blogImg {
          url
        }
      }
    }
  }
`

export default JobDetailsTemplate
